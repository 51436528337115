


















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import * as _ from 'lodash';
import { Select, Option, OptionGroup } from 'element-ui';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import BranchesModel from '../models/branches.model';
import CarsSharedService, { CarsSharedServiceS } from '../cars-shared.service';
import CarsFiltersService, { CarsFiltersServiceS } from '../cars-filters.service';
import CarsAnalysisFiltersService, { CarsAnalysisFiltersServiceS } from '../cars-analysis-filters.service';

@Component({
    components: {
        Select,
        Option,
        OptionGroup,
        StyledSearchbar,
    },
})
export default class CustomerSelect extends Vue {
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(CarsAnalysisFiltersServiceS) carsAnalysisFiltersService!: CarsAnalysisFiltersService;
    @Inject(DocumentFiltersServiceS) protected documentFiltersService!: DocumentFiltersService;

    selectedChain = null;

    get chains(): BranchesModel[] {
        const { branches } = this.carsSharedService;
        return branches;
    }

    setChain(value: BranchesModel) {
        this.carsSharedService.currentChain = value;

        const routeName = this.$route.name;
        let saveDataKey = '';
        if (routeName && ['car-rates-calendar', 'analysis.table', 'car-rates-table', 'car-rates-graph'].includes(routeName)) {
            saveDataKey = 'rates';
        }
        if (routeName) {
            this.carsSharedService.setUserFilterValuesStorageBeforeSwitch(saveDataKey);
        }

        document.location.reload();
    }
}
