import { Component, Mixins } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import BaseOtelMixin from '@/modules/cars/modules/open-telemetry/mixins/common/base-otel.mixin';
import { LOGTYPE } from '@/modules/open-telemetry/constants';
import CarsRateAlertService, { CarsRateAlertServiceS } from '@/modules/cars/car.rate-alert.service';

/**
 * Mixin for rates pages.
 * Open OTEL span on before component mount and end it, on finishing document loading
 * (it doesn't take in count time for rerender component after document update).
 */
@Component
export default class CarActionsOtelLogs extends Mixins(BaseOtelMixin) {
    static readonly spanPrefix = LOGTYPE.CLICK;

    addOtelLogs(postfix: string) {
        this.postfix = postfix;
        this.startSpan(CarActionsOtelLogs.spanPrefix);
        this.endSpan(CarActionsOtelLogs.spanPrefix, { sendLogs: true });
    }
}
